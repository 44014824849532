<template>
  <a-modal v-model="addPlaceVisibles" :title="this.names" @onCancel="handleCancel" @ok="handleSubmit">
    <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
      <a-form-model-item has-feedback label="抬头" prop="accountName">
        <a-input v-model.trim="query.accountName" autocomplete="off" placeholder="请输入抬头" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="开户行" prop="accountBank">
        <a-input v-model.trim="query.accountBank" autocomplete="off" placeholder="请输入开户行" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="银行账号" prop="accountCode">
        <a-input v-model.trim="query.accountCode" autocomplete="off" placeholder="请输入银行账号" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="注册地址" prop="registerAddress">
        <a-input v-model.trim="query.registerAddress" autocomplete="off" placeholder="请输入注册地址" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="税号" prop="taxNumber">
        <a-input v-model.trim="query.taxNumber" autocomplete="off" placeholder="请输入税号" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="电话" prop="taxPhone">
        <a-input v-model.trim="query.taxPhone" autocomplete="off" placeholder="请输入电话" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addTaxInfo } from '@/api/financialManagementA'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      valPush: '',
      imagesPush: [],
      contractTypes: '',
      agentUser: [],
      tps: 1,
      datts: [],
      defectSearchForm: {},
      imagesUrl: '',
      url: '...',
      fileList: [],
      sysAgent: [],
      businessUser: [],
      industry: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + 'common/upload',
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      rules: {
        accountName: [{ required: true, message: '请输入抬头', trigger: 'blur' }],
        accountBank: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
        accountCode: [{ required: true, message: '请输入银行账号', trigger: 'blur' }],
        registerAddress: [{ required: true, message: '请输入注册地址', trigger: 'blur' }],
        taxNumber: [{ required: true, message: '请输入税号', trigger: 'blur' }],
        taxPhone: [{ required: true, message: '请输入电话', trigger: 'blur' }]
      },
      appList: [],
      positionList: [],
      templateList: [],
      names: ''
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Number,
      default: 0
    },
    appId: {
      default: '',
      type: String
    },
    placeInfo: {
      default: () => ({}),
      type: Object
    },
    supplier: {
      type: Number,
      default: 0
    }
  },
  watch: {
    placeInfo: {
      handler (val) {
        this.names = '创建抬头'
      },
      deep: true,
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  mounted () {},
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addPlaceVisibles: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    onChange (date, dateString) {
      this.query.times = dateString
      this.query.contractStartTime = this.query.times[0]
      this.query.contractEndTime = this.query.times[1]
    },
    onContract (val) {
      this.valPush = val
    },

    changeUser (val) {},

    handleCancel () {
      this.isAdd = ''
      this.addPlaceVisibles = false
    },
    // 添加抬头
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const resp = await addTaxInfo(this.query)
          if (resp.code === 200) {
            this.$message.success('添加成功!')
            this.$emit('changeAppInfo', 'add')
          }
          this.query = {}
          this.$emit('changePlaces')
          this.addPlaceVisibles = false
        }
      })
    }
  }
}
</script>

<style></style>
